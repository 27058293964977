import React from 'react'
import { BASE_URL } from '../config/config'
import { Skeleton, Space } from 'antd'

function SelectedPlayer({selectedCharacter,profileImage}) {
  return (
    <>
    
    <div className='selected-player d-flex align-items-center flex-column justify-content-center' style={{marginTop:'10rem', background:"#f2f0eb", borderRadius:"8px"}} >
        { !profileImage ? 
              <Space><Skeleton.Avatar size={70} shape={'circle'} active={true} /> </Space>
              : 
          <div className='selected-player-image' >
              <img src={`${BASE_URL}/images/${profileImage}`} alt={profileImage} />
          </div> 
        }
        <p className='initial-message'>
          Start a conversation with <span style={{color:"rgb(78, 177, 81)"}} >{selectedCharacter.first_name} {selectedCharacter.last_name} </span>
           or select starter message</p>
    </div>
    </>
  )
}

export default SelectedPlayer