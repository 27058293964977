
module.exports = {

  BASE_URL: "https://athleteschatserver.com.previewthumbnail.com",
  // // BASE_URL: "http://localhost:5000",
  // BASE_URL: "http://192.168.0.110:5000",
  LOGIN_ENDPOINT :'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCRDATM_cwyn5RlSsskReBzZO28ghmjRo8',
  MESSAGE_API :"https://us-central1-chatfai-sb.cloudfunctions.net/chat",
  EMAIL: "h_butt12@aol.com",
  PASSWORD: "!secure@all",
  UPDATETOKEN: "characters/updateToken/04b23be3-0ca7-4553-8559-83f157a7728f-2e28fee191fd03d6c7a9e9394b1d082d",
  UPDATEPLAYERSID: "characters/updatePlayersId"
};