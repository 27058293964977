/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import React from 'react'
import {Buffer} from 'buffer'
// import Header from '../components/Header'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../config/config'
// import { Login } from '../API/Login'
// import { UpdatePlayerId } from '../API/UpdatePlayerId'
import Logo from '../static/Logo.png'
import '../components/players.css'
import '../components/Header.css'
import noResultFound from '../static/no_result.gif'

function Character() {
    document.title = "Players"
    const [characters, setCharacters] = React.useState([])
    const [playerName, setPlayerName] = React.useState('')
    const [debounceTimer, setDebounceTimer] = React.useState(null);
    const [loader, setLoader] = React.useState(false);

    React.useEffect(() =>{

      if(playerName){
        clearTimeout(debounceTimer);
        const newDebounceTimer = setTimeout(() => {
          setLoader(true)
          axios.get(`${BASE_URL}/characters/searchPlayers?name=${playerName}`)
          .then((res) => {
                  setCharacters(res.data.players);
                  setLoader(false)
                })
                .catch((err) => {
                  console.log(err);
                  setLoader(false)
                });
            }, 500);
        
            setDebounceTimer(newDebounceTimer);
          }else{
            axios.get(`${BASE_URL}/characters/fetchCharacters`)
              .then((res)=>{
                  setCharacters(res.data.characters)
                }).catch((err)=>{
                  console.log(err);
              })
            }
              
              return () => {
                if (debounceTimer) {
                  clearTimeout(debounceTimer);
                }

              };

          }, [playerName])
   
  return (
    <div className='mb-5'>

        <nav className="navbar navbar-expand-lg bg-light py-3">
            <div className="container header">
             <Link className='d-flex align-items-start m-0 p-0 logo' to="/">
                Asky<img style={{ width: '100%' }} src={Logo} alt="Logo" />urplayer
             </Link>
            </div>
        </nav>

        <div className="search-bar text-center">
            <h2>Pick your favorite player and start chat</h2>
            <p>or search player by name</p>
              <input autoFocus type="text" className='search-input' placeholder='Type name' value={playerName} onChange={(e) => setPlayerName(e.target.value)} />
                {loader ? 
                <div className="spinner-border text-secondary spinner-border-sm" style={{position:"relative", right:"2rem"}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div> : ""}
        </div>
        
        <div className='mt-3 d-flex align-items-start justify-content-center flex-wrap'>

             {
           <>
           {characters.length ? 
            
           
                characters.map((character,index) => {
                    const image =  Buffer.from(character.image.data, 'base64');
                    return (
                        <Link to={`/chat/${character.id}`}  className='mx-3 characters text-center mt-3' key={character.id}>
                            <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                                <div className='player-img'>
                                    <img src={`${BASE_URL}/images/${image}`} alt={image} />
                                </div>
                                {character.first_name ? <p className='my-3 playerName' >{character.first_name} {character.last_name}</p> : ""}
                            </div>
                        </Link>
                    )})
                  :
                  <div className='mt-3'>
                    {playerName ? 
                    <div className='text-center'>
                      <p style={{fontFamily:"Poppins", fontSize:"20px",fontWeight:"600"}}>You will find this player soon</p>
                      <img style={{width:"300px"}} src={noResultFound} alt="No Result found" />
                    </div>
                     :
                    <div className="spinner-border" style={{width:"3rem",height:"3rem"}} role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>}
                  </div>
                  
                }
            </>
            }
        </div>
    </div>
  )
}

export default Character