import './App.css';
// import MessageBox from './components/MessageBox';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Chat from './pages/Chat';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Players from './pages/Players';
import React from 'react';
import Dashboard from './pages/Dashboard';
// import { config } from 'dotenv';

function App() {

  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={ <Players/>} />
        <Route path='/chat/:id' exact element={ <Chat />} />
        <Route path='/dashboard/messages' exact element={ <Dashboard />} />
      </Routes>
    </BrowserRouter>

  )
  }

export default App;
