/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import axios from 'axios';
import './MessageBox.css'
import Typing from '../static/typing-dots.gif'
import ReceivedMessage from '../components/ReceivedMesssage'
import { BASE_URL } from '../config/config';
import SelectedPlayer from './SelectedPlayer';
import UserAvatar from '../static/useravatar.png'

function MessageBox(props) {

    const { selectedCharacter, profileImage, characterId, userId, token, setToken} = props
    const [message, setMessage] = React.useState('')
    const [updatedUserId, setUserId] = React.useState('')
    const [myChat, setMyChat] = React.useState([])
    const [generatedChat, setGeneratedChat] = React.useState([])
    const [isgenerating, setIsgenerating] = React.useState(false)
    const chatContainerRef = React.useRef()
    const inputRef = React.useRef()
    const imageRef = React.useRef();
    const [defaultMessages, setDefaultMessages] = React.useState([
      "When is your upcoming match?",
      "When was your last match?",
      "What is the score of your previous matches?",
      "Tell me the score of your last match",
      "What is the name of your team?",
      "What is your position in your team?",
      "When did you join the team?",
      "How do you prepare for a big game?",
      "What is your favorite pre-game ritual?",
      "Can you share a memorable moment from your career?",
      "Who is your sports idol?",
      "What is your favorite stadium to play in?",
      "How do you handle the pressure during important matches?",
      "What is the key to maintaining peak performance?",
      "Do you have any advice for aspiring athletes?",
      "What hobbies do you enjoy outside of sports?",
      "What is your favorite post-game meal?",
      "How do you stay motivated during tough times?",
      "What goals do you have for the upcoming season?",
      "Who is the toughest opponent you've faced?",
      "What is your favorite sports-related movie or book?",
      "How do you balance your professional and personal life?",
      "What is the most challenging aspect of being a professional athlete?"

    ])
    
    const handleMessage = async (defaultMessage = null, updatedToken) => {

        setMessage('');
        const currentTime = getCurrentTime()
        const myMsgs = myChat.map((msg, index) => ({ content: msg.text, sender: "me" }));
        const resMsgs = generatedChat.map((msg, index) => ({ content: msg.text, sender: "you" }));

        const conversation = []; 
        for (let i = 0; i < Math.max(myMsgs.length, resMsgs.length); i++) {
          if (i < myMsgs.length) {
            conversation.push(myMsgs[i]);
          }
          if (i < resMsgs.length) {
            conversation.push(resMsgs[i]);
          }
        }

        let payloads = { 
          data : {
              conversation : conversation,
              id : characterId,
              message: defaultMessage ? defaultMessage : message,
              
            }}
          const auth = {
            token: updatedToken ? updatedToken : token,
            userId: updatedUserId ? updatedUserId : userId,
            playerName: selectedCharacter.first_name
          }
         
        setMyChat([...myChat, {text: defaultMessage ? defaultMessage : message, timestamp: currentTime}]);
        setIsgenerating(true)
        
          return await axios.post(`${BASE_URL}/chat`, { payloads, auth }
            ).then((res) => {
                return Promise.resolve(res)
            }).catch((error) => {
                return error.response.data.error.status
            })
      };

    const sendMessage = async (defaultMessage = null, cycle = 0, updatedToken) =>{
      let maxRetries = 2       
       
      if(cycle >= maxRetries){
        return
      }
      try {
            const returnResponse = await handleMessage(defaultMessage, updatedToken)
            console.log("returnResponse",returnResponse);
            if(returnResponse === "UNAUTHENTICATED" || returnResponse === "INVALID_ARGUMENT"){
              
              const res = await axios.post(`${BASE_URL}/updateRefreshToken`, {userId})
              console.log("res.data.token",res.data.token);
              sendMessage(defaultMessage, ++cycle, res.data.token)
            }else{
              console.log("updatedUserId",returnResponse.data.updatedUserId, "userId",userId,"updatedChracterId",returnResponse.data.updatedCharacterId);
              setUserId(returnResponse.data.updatedUserId)
              setToken(returnResponse.data.token)
              window.history.pushState(null,null,returnResponse.data.updatedCharacterId)
              const currentTime = getCurrentTime()
              setIsgenerating(false)
              setGeneratedChat([...generatedChat, { text: returnResponse.data.reply, timestamp: currentTime }]);
            }
        } catch (error) {
          console.log("Error in sendMessage", error);
          setIsgenerating(false);
      }
    }
     
    React.useEffect(()=>{
        chatContainerRef.current?.scrollIntoView({behavior: 'smooth'});
        const handleImageLoad = () => {
          chatContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
        };
      
        imageRef.current?.addEventListener('load', handleImageLoad);
        if (isgenerating) {
          inputRef.current.disabled = true;
        } else {
          inputRef.current.disabled = false;
          inputRef.current.focus();
        }
        return () => {
          imageRef.current?.removeEventListener('load', handleImageLoad);
        };
      }, [generatedChat,myChat,imageRef])

    const getCurrentTime = () => {
        const newTime = new Date();
        let hours = newTime.getHours();
        const minutes = newTime.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${hours}:${formattedMinutes} ${ampm}`;
      };

      const deleteMessage = (index) => {
        const updatedMessages = defaultMessages.filter((_, i) => i !== index);
        setDefaultMessages(updatedMessages);
      }

      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          sendMessage();
        }
      };

  return (

    <>
      <div className="container default-messages text-center"  >
        {
          defaultMessages.map((msgs,index) => {
            return (
                <button disabled={isgenerating ? true : false} key={index} onClick={() => {sendMessage(msgs); deleteMessage(index)}} className='default-message' >{msgs}</button>
                )
          })
        }
   
      </div>

      <div className='container d-flex align-ietms-center justify-content-center' id='messageBox-container'>

          <div className="message-inner-box d-flex flex-column">
            {
            myChat.map((userMsg, index) => (
              <React.Fragment key={index}>
                <div className='send-message-box d-flex align-items-center justify-content-end' >

                  <div className='d-flex'>
                      <div className="send-message">
                          <span className="name-time m-0 p-0 d-flex justify-content-between">
                              <p className='user m-0 pb-2'>You</p>
                              <p className="message-time m-0 pb-2">{userMsg.timestamp}</p>
                          </span>
                          <p className='send-text'>{userMsg.text}</p>
                      </div>
                      <div className='avatar-div'>
                        <img className='avatar' src={UserAvatar} alt={UserAvatar} />
                      </div>
                    </div>
                </div>
                {
                index < generatedChat.length && (
                  <>
                  <ReceivedMessage playerName={selectedCharacter.first_name + ' ' + selectedCharacter.last_name} chatContainerRef={chatContainerRef} profileImage={profileImage} playerMessage={generatedChat[index]}/>
                  </>
                )}
              </React.Fragment>
              ))}
              {isgenerating && ( <img src={Typing} alt="Typing" id='typing-image' ref={imageRef} /> )}
              {!myChat.length && <SelectedPlayer selectedCharacter={selectedCharacter} profileImage={profileImage}/>}

          </div>
          
          <form className='form container d-flex align-items-center justify-content-center' onSubmit={(e) => { e.preventDefault(); handleMessage();}}>
            <div className='outer-input d-flex align-items-start justify-content-start' style={{borderRadius:'8px',position:'relative' }}>
                <textarea className='message-input' disabled={isgenerating} ref={inputRef} type="text" name='message' rows={1}
                  placeholder='Type a message' value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyPress} spellCheck={true}></textarea>
                <button disabled={!message.length} style={{ position: 'absolute', right: '3%', border: 'none', outline: 'none', background: 'none',
                    fontSize: '30px' , color: !message.length ? 'grey' : '#4CAF50'}} onClick={() => sendMessage("")} > 
                    &#10140;
                </button>
            </div>
          </form>
          
      </div>

      <div  id='bottom-space' ></div>
      
    </>
  )
}


export default MessageBox

