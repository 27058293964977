import axios from 'axios'
import React from 'react'
import { BASE_URL } from '../config/config'
import { Link } from 'react-router-dom'
import Logo from '../static/Logo.png'

function Dashboard() {

    const [messages, setMessages] = React.useState([])
    const [remainingMessages, setRemainingMessages] = React.useState(0)
    const [accountRemainingMessages, setAccountRemainingMessages] = React.useState([])

    React.useEffect(() => {
        axios.get(`${BASE_URL}/fetchAllMessages`)
        .then((res) => {
            setMessages(res.data.messages.reverse())
            setRemainingMessages(res.data.sumOfRemainingMessages)
            setAccountRemainingMessages(res.data.individualAccountMessages)
        }).catch((err) => {
            console.log(err.response.data.error);
        })

    }, [])

  return (

    <>
        <nav className="navbar navbar-expand-lg bg-light py-3">
            <div className="container header">
             <Link className='d-flex align-items-start m-0 p-0 logo' to="/">
                Asky<img style={{ width: '100%' }} src={Logo} alt="Logo" />urplayer
             </Link>
            </div>
        </nav>
   
        <p style={{fontFamily:"Poppins", fontSize:"26px",fontWeight:"600", textAlign:"center",marginTop:"10px"}} >All Messages</p>
        
        <div className='px-5'>

            {!messages.length ? 
                <div className='text-center mt-5'>
                    <p style={{fontFamily:"Poppins",fontSize:"20px",fontWeight:"600"}} >Fetching messages...</p> 
                    <div className="spinner-border text-secondary spinner-border-lg mt-4" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
        :   
            <>
                <div className='d-flex align-items-start justify-content-start' >
                    <p className='p-2 m-0' style={{border:"1px solid grey",fontFamily:"Poppins", fontSize:"16px",fontWeight:"600" }} >Total Remaining Messages : {remainingMessages}</p>
                </div>
                
                <p className='my-4' style={{fontFamily:"Poppins", fontSize:"20px",fontWeight:"600",textAlign:"center"}}>Each account remaining messages</p>
                
                <table className="table table-bordered" >
                            <thead>
                                <tr className='table-primary'>
                                    <th style={{fontFamily:"Poppins", fontSize:"18px",fontWeight:"600"}} scope="col">Account Id</th>
                                    <th style={{fontFamily:"Poppins", fontSize:"18px",fontWeight:"600"}} scope="col">Remaining Messages</th>
                                </tr>
                            </thead>
                            <tbody >
                            {
                                accountRemainingMessages.map((msgs,index) => {
                                    return(
                                        <tr key={index}>
                                            <td style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >{msgs.same_players_account_id}</td>
                                            <td style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >{msgs.remaining_messages}</td>
                                        </tr>
                                        )
                                    })
                                }
                            </tbody>
                </table>

                <p className='my-4' style={{fontFamily:"Poppins", fontSize:"20px",fontWeight:"600",textAlign:"center"}}>Users Messages with players</p>
                <table className="table table-bordered">
                    <thead>
                        <tr className='table-primary'>
                            <th style={{fontFamily:"Poppins", fontSize:"18px",fontWeight:"600"}} scope="col">Id</th>
                            <th style={{fontFamily:"Poppins", fontSize:"18px",fontWeight:"600"}} scope="col">Messages</th>
                            <th style={{fontFamily:"Poppins", fontSize:"18px",fontWeight:"600"}} scope="col">Player Name</th>
                        </tr>
                    </thead>
                    <tbody >
                    {
                        messages.map((msgs,index) => {
                            return(
                                <tr key={msgs.id}>
                                    <th style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >{msgs.id}</th>
                                    <td style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >{msgs.user_message}</td>
                                    <td style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >{msgs.player_name}</td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
            }

        </div>

        {/* <div className='d-flex align-items-start justify-content-around flex-wrap'>
           
            {!messages.length || !accountRemainingMessages.length ? 
                <div className='text-center mt-5'>
                    <p style={{fontFamily:"Poppins",fontSize:"20px",fontWeight:"600"}} >Fetching messages...</p> 
                    <div className="spinner-border text-secondary spinner-border-lg mt-4" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :   
                <>
                    <div className="remaining-messages-of-accounts m-2 p-2" style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius:"8px", width:"420px"}}>
                            <div className='d-flex align-items-start justify-content-around p-2' style={{boxShadow:"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", borderRadius:"8px"}} >
                                <p className='m-0 p-0' style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >Accounts</p>
                                <p className='m-0 p-0' style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}} >Remaining Messages</p>
                            </div>
                            <div className='mt-3'>
                                {accountRemainingMessages.map((msgs,index)=>{
                                    return (
                                        <div key={index} className='d-flex align-items-start justify-content-around' >
                                            <p style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}}> {msgs.same_players_account_id} </p>
                                            <p style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600", marginLeft:"20px"}}> {msgs.remaining_messages}</p>
                                        </div>
                                    )
                                })}
                            </div>
                    </div>
            
                    <div className='d-flex align-items-center justify-content-center flex-column'  >
                        
                        <div className='m-2 p-2' style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius:"8px", maxWidth:"700px"}} >
                            <p className='text-center p-2' style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600",boxShadow:"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",borderRadius:"8px" }} >Total Remaining Messages : {remainingMessages}</p>
                            <p className='text-center' style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600" }}>Users Messages</p>
                            {
                                messages.map((msgs,index) => {
                                    return (
                                        <div key={index} className='p-1 d-flex align-items-start' >
                                            <p style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600"}}> {msgs.id}) </p>
                                            <p style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600", marginLeft:"20px"}}> {msgs.user_message}</p>
                                            <p style={{fontFamily:"Poppins", fontSize:"16px",fontWeight:"600", marginLeft:"20px"}}> player Name: {msgs.player_name}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </>
            }

        </div> */}

    </>
  )
}

export default Dashboard
