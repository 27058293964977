/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';
import MessageBox from '../components/MessageBox'
import {Buffer} from 'buffer'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../config/config';
import Logo from '../static/Logo.png'
import { Skeleton, Space } from 'antd'
// import '../components/MessageBox.css'
import '../components/Header.css'


function Chat() {
    const [selectedCharacter,setSelectedChracter] = React.useState([])
    document.title = `Chat`
    const { id } = useParams();
    const [profileImage,setProfileImage] = React.useState('')
    const [characterId,setCharacterId] = React.useState('')
    const [userId,setUserId] = React.useState('')
    const [token,setToken] = React.useState('')

    React.useEffect(()=>{
        axios.get(`${BASE_URL}/characters/fetchCharacter/${id}`)
        .then((res) => {
            console.log("res",res);
            setUserId(res.data.character.user_id)
            setToken(res.data.token)
            const image = Buffer.from(res.data.character.image.data, 'base64');
            setProfileImage(image)
            setSelectedChracter(res.data.character)
            setCharacterId(res.data.character.id);
        }).catch((err) => {
            console.log(err);
        })
    },[])

  return (
    <div>

        <nav className="navbar navbar-expand-lg bg-light py-3">
            <div className="container header">
            <Link className='logo d-flex align-items-start m-0 p-0' to="/">
                Asky<img style={{ width: '100%' }} src={Logo} alt="Logo" />urplayer
            </Link>
                {profileImage ?
                <div className="character d-flex align-items-center">
                    <div style={{maxWidth:'30px',overflow: 'hidden', height:'30px', borderRadius:'100px'}}>
                        <img style={{width:'100%',height:'100%',objectFit:'contain'}} src={`${BASE_URL}/images/${profileImage}`} alt={profileImage} />
                    </div>
                    <span className='header-player' >{selectedCharacter.first_name} {selectedCharacter.last_name}</span>
                </div>
                    : 
                    <Space>
                        <Skeleton.Avatar size={30} shape={'circle'} active={true} />
                        <Skeleton.Input size={25} shape={'square'} active={true} />
                    </Space> 
                    }
            </div>
        </nav>
       
        <div className="chat-screen">
            <MessageBox token={token} setToken={setToken} userId={userId} characterId={characterId} profileImage={profileImage} selectedCharacter={selectedCharacter}/>
        </div>
    </div>
  )
}

export default Chat