/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { BASE_URL } from '../config/config';
import '../components/RecievedMessage.css'

function ReceivedMesssage({playerMessage, chatContainerRef, playerName, profileImage}) {
  const { text, timestamp } = playerMessage;
 
  return (

    <div className='receive-message-box d-flex align-items-center justify-content-start' ref={chatContainerRef}>
      <div className='d-flex'>
        <div className='user-image-div' >
          <img className='user-image'  src={`${BASE_URL}/images/${profileImage}`} alt={profileImage} />
        </div>
        <div className='received-message '>
          <span className="name-time m-0 p-0 d-flex justify-content-between">
            <p className='player-name m-0 pb-2'>{playerName}</p>
            <p className="message-time m-0 pb-2">{timestamp}</p>
          </span>
          <p id='received-text'>{text}</p>
        </div>
      </div>
    </div>
  )
}

export default ReceivedMesssage